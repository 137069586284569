
// import { Button } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import AddressForm from "./AddressForm";
import Map from "./Map";
import "mapbox-gl/dist/mapbox-gl.css"; 
import { useState, useEffect } from "react";
import './GetAnEstimate.css'
import axios from 'axios'

// const API_KEY = 'AIzaSyDiEyzfpyATfaedVaNTsQRrIAf252_kuXU';
const API_KEY = 'AIzaSyCprjFEozrLpAhn1jJMrhMSRrh8U3trjHM';

const GetAnEstimate = () => {
    const [address, setAddress] = useState({
        streetAndNumber: "",
        place: "",
        region: "",
        postcode: "",
        country: "",
        latitude: "",
        longitude: "",
      });

    const [submitted, setSubmitted] = useState(false);
    const [roofSize, setroofSize] = useState(0);
    const [estimateData, setestimateData] = useState(0);
    
    const handleFormSubmit = async (event) => {
        console.log(process.env.MAPBOX_API_KEY);
        event.preventDefault();
        if (address.streetAndNumber) {
            try {
                await fetchEstimate(address.latitude, address.longitude);
            } catch (error) {
                console.error("Error fetching estimate:", error);
            }
            console.log("Selected address:", address);
            setSubmitted(true);
            console.log(estimateData);
        }
    };

    const updateCoordinates = (latitude, longitude) => {
        setAddress({ ...address, latitude, longitude });
    };


    const fetchEstimate = async (latitude, longitude) => {
        const url = `https://solar.googleapis.com/v1/buildingInsights:findClosest?location.latitude=${latitude}&location.longitude=${longitude}&requiredQuality=HIGH&key=${API_KEY}`;
        try {
            const response = await axios.get(url);
            // Offset to be in SQ Ft, not meters
            var roof = response.data.solarPotential.buildingStats.areaMeters2 *  3.281;
            var estimate = roof * 0.85;
            setroofSize(Math.round(roof));
            setestimateData(Math.round(estimate));
        } catch (error) {
            console.error("Error fetching estimate:", error);
        }
    };

    useEffect(() => {
        console.log("Roof Size:", roofSize);
        console.log("Estimate Data:", estimateData);
    }, [estimateData, roofSize]);
    


    return (
        <div className='get-an-estimate-container'>
            <div className='get-an-estimate-card'>
                <div className='get-an-estimate-card-title'>
                    Get an Estimate
                </div>
                <div className='get-an-estimate-card-text'>
                    Interested in listing your property? Get an estimate of how much revenue you could generate
                </div>
            </div>
            {!submitted &&
                <div className="form">
                        <Map
                        longitude={address.longitude ? address.longitude : -117.826508}
                        latitude={address.latitude ? address.latitude : 33.684566}
                        updateCoordinates={updateCoordinates}
                        address={address}
                        setAddress={setAddress}
                        />

                    <AddressForm
                        onSubmit={handleFormSubmit}
                        address={address}
                        setAddress={setAddress}
                    />
                </div>
            }

            { submitted && 
                <div className="estimate-details">
                    <div className="estimate-detail">
                        <p className="detail-label">Address:</p>
                        <p className="detail-value">{address.streetAndNumber}, {address.place}, {address.region}, {address.postcode}</p>
                    </div>
                    <div className="estimate-detail">
                        <p className="detail-label">Roof Size:</p>
                        <p className="detail-value">{roofSize.toLocaleString()} Square Feet</p>
                    </div>
                    <div className="estimate-detail">
                        <p className="detail-label">Revenue (Estimated):</p>
                        <p className="detail-value">${estimateData.toLocaleString()} per year</p>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>

            }
        </div>
    );

}

export default GetAnEstimate;
