import React from 'react';
import './ContactUs.css';
import { useState, useEffec } from 'react';
import axios from 'axios';

const ContactUs = () => {

  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${apiUrl}/api/send-email`, formData);
      window.location.reload();
      //console.log('Message sent.');
    } catch (error){
        // ADD RETURN ERROR MESSAGE TO USER
        //console.log('Error: ', error) // REMOVE
    }

    setFormData({
      name: '',
      email: '',
      subject: '',
      message: ''
    });


  };

  return (
    <div className='contact-us-container'>
      <div className='contact-us-container-top'>
        <div className='contact-us-container-top-left' >
            <div className='contact-us-container-top-left-text-top'> Talk to us! </div>
            <div className='contact-us-container-top-left-text-bottom'> Don’t hesitate to reach out with the contact information below, or send a message using the form! </div>
        </div>
        <div className='contact-us-container-top-right' >
            <img className='contact-us-container-top-right-image' src='https://www.searchenginejournal.com/wp-content/uploads/2023/02/contact-1x-63f497a00aa4a-sej.webp' />
        </div>
      </div>
      <div className='contact-us-container-bottom'>
          <div className='contact-us-container-bottom-left'>
            <div className='contact-us-container-bottom-left-title'> Write us a message </div>
            <div className='contact-us-container-input-container'>
              <div className='contact-us-container-input-container-text'> Your Name</div>
              <input className='contact-us-container-bottom-left-input' type='text' name='name' placeholder='Name' value={formData.name} onChange={handleChange} />
            </div>
            <div className='contact-us-container-input-container'>
            <div className='contact-us-container-input-container-text'> Your email </div>
              <input className='contact-us-container-bottom-left-input' type='text' name='email' placeholder='Email' value={formData.email} onChange={handleChange} />
            </div>
            <div className='contact-us-container-input-container'>
            <div className='contact-us-container-input-container-text'> Subject </div>
              <input className='contact-us-container-bottom-left-input' type='text' name='subject' placeholder='Subject' value={formData.subject} onChange={handleChange} />
            </div>
            <div className='contact-us-container-input-container'>
              <div className='contact-us-container-input-container-text'> Your message (optional) </div>
              <textarea className='contact-us-container-bottom-left-input-2' name='message' placeholder='Your message' value={formData.message} onChange={handleChange} />
            </div>
            <button onClick={handleSubmit} className='contact-us-container-bottom-left-button'> Submit </button>
          </div>
          <div className='contact-us-container-bottom-right'>
            <div className='contact-us-container-bottom-right-text-top-container'>
              <div className='contact-us-container-bottom-right-text-title' >We welcome your calls and emails </div>
              <div className='contact-us-container-bottom-right-text'> +1 (925) 381-3895 </div>
              <div className='contact-us-container-bottom-right-text'> Contact@starfinventures.com </div>
            </div>
            <div className='contact-us-container-bottom-right-text-bottom-container'>
              <div className='contact-us-container-bottom-right-text-title'> Our Office </div>
              <div className='contact-us-container-bottom-right-text'> Irvine, CA </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default ContactUs
